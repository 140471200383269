import * as Sentry from '@sentry/gatsby';
import { BrowserTracing } from '@sentry/tracing';

const SENTRY_DSN = process.env.GATSBY_SENTRY_DSN;
const APP_ENVIRONMENT = process.env.GATSBY_APP_ENVIRONMENT;
const APP_VERSION = process.env.GATSBY_APP_VERSION;

Sentry.init({
    dsn: SENTRY_DSN,
    enabled: !!SENTRY_DSN,
    environment: APP_ENVIRONMENT,
    release: APP_VERSION,
    integrations: [new BrowserTracing()]
});
