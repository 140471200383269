export function trackEvent(eventName: string, props: { [key: string]: any } = {}) {
    if (process.env.NODE_ENV === 'test') {
        return;
    }

    if (process.env.NODE_ENV !== 'production') {
        console.groupCollapsed(`Track PostHog event: ${eventName}`);
        console.log(`Name: ${eventName}`);

        if (props) {
            console.log('Props:', props);
        }

        console.groupEnd();
    }

    execute((posthog) => {
        posthog.capture(eventName, props);
    });
}

export function createTrackEvent(...args: Parameters<typeof trackEvent>): () => ReturnType<typeof trackEvent> {
    return () => trackEvent(...args);
}

export function trackPageview(pathname) {
    if (process.env.NODE_ENV === 'test') {
        return;
    }

    const url = new URL(pathname, window.location.href);

    if (process.env.NODE_ENV !== 'production') {
        console.groupCollapsed(`Track PostHog pageview: ${pathname}`);
        console.log(`URL: ${url.toString()}`);
        console.groupEnd();
    }

    execute((posthog) => {
        // TODO: Track correct URL
        posthog.capture('$pageview', {
            $current_url: url.toString(),
            $pathname: pathname
        });
    });
}

function execute(callback: (posthog: Exclude<typeof window.posthog, undefined>) => void) {
    if (typeof window.posthog === 'object') {
        callback(window.posthog);
    }
}
